




































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useNotification } from '@/composition/notification';
import FcVideo from '@/components/FcVideo.vue';
import FcThumbnail from '@/components/FcThumbnail.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import { useVideo, videoEndScreenType } from '@/composition/video';

export default defineComponent({
  name: 'Video',
  components: {
    FcVideo,
    FcThumbnail,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    videoId: {
      type: String,
      require: false,
      default: '',
    },
  },
  setup({ videoId }, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('videos'));
    const { video } = useVideo(videoId);
    const isNewCreate = ref(!videoId); // 新規作成かどうか
    const isLoading = ref(true);
    const isSaving = ref(false);
    const inputExtraDomain = ref('');
    const videoPath = ref('');
    const extraEmbedDomains = ref<string[]>([]);
    const { notify, error } = useNotification();

    const displayVideoEndScreenTypes = Object.values(videoEndScreenType).map((displayEndScreenType) => {
      return { text: displayEndScreenType, value: displayEndScreenType };
    });

    function addEmbedDomain() {
      try {
        video.setExtraEmbedDomains(inputExtraDomain.value);
        extraEmbedDomains.value = video.getExtraEmbedDomains();
        inputExtraDomain.value = '';
      } catch (e) {
        error(e);
      }
    }
    function deleteEmbedDomain(domain: string) {
      try {
        video.deleteExtraEmbedDomains(domain);
      } catch (e) {
        error(e);
      }
      extraEmbedDomains.value = video.getExtraEmbedDomains();
    }

    async function createVideo() {
      isSaving.value = true;
      const _videoPath = videoPath.value;
      if (!_videoPath) {
        error('動画をアップロードしてください');
        isSaving.value = false;
        return;
      }
      if (inputExtraDomain.value) {
        error('ドメインの追加を行なうか必要なければ入力を削除してください');
        isSaving.value = false;
        return;
      }

      isLoading.value = true;
      try {
        const videoId = await video.createVideo(_videoPath);
        isLoading.value = false;
        isSaving.value = false;
        notify('新しい動画を作成しました。');
        videoPath.value = '';
        isNewCreate.value = false;
        context.root.$router.push(`/videos/${videoId}`);
      } catch (e) {
        isLoading.value = false;
        isSaving.value = false;
        error(e);
      }
    }
    async function updateVideo() {
      isSaving.value = true;
      if (inputExtraDomain.value) {
        error('ドメインの追加を行なうか必要なければ入力を削除してください');
        isSaving.value = false;
        return;
      }
      isLoading.value = true;
      try {
        await video.updateVideo();
        notify('動画を更新しました。');
      } catch (e) {
        error(e);
      } finally {
        isLoading.value = false;
        isSaving.value = false;
      }
    }

    async function init() {
      await video.getVideo();
      extraEmbedDomains.value = video.getExtraEmbedDomains();
      isLoading.value = false;
    }
    init();

    return {
      pageTitle: '動画',
      myRoleSettings,
      video,
      isNewCreate,
      isLoading,
      isSaving,
      videoPath,
      inputExtraDomain,
      extraEmbedDomains,
      displayVideoEndScreenTypes,
      addEmbedDomain,
      deleteEmbedDomain,
      createVideo,
      updateVideo,
    };
  },
});
