

















import { defineComponent, ref, watch } from '@vue/composition-api';
import { download, upload } from '@/admin/file';
import { useNotification } from '@/composition/notification';

export interface HTMLElementEvent<T extends HTMLElement> extends Event {
  target: T;
}
export default defineComponent({
  props: {
    value: {
      type: String,
      default: '',
      required: true,
    },
  },
  setup(props, context) {
    const notification = useNotification();
    const src = ref('');
    const isUploading = ref(false);
    const fileInput = ref();

    function removeVideo() {
      src.value = '';
      context.emit('input', '');
    }
    function onFocus() {
      fileInput.value.click();
    }
    async function onFileChange(e: HTMLElementEvent<HTMLInputElement>) {
      const files = e.target.files || ([] as File[]);
      if (files.length === 0) return;
      if (
        !['video/mp4', 'video/ogg', 'video/m4v', 'video/webm', 'video/mpeg', 'video/quicktime', 'video/x-flv'].includes(
          files[0].type
        )
      ) {
        notification.error('動画（mp4, ogg, m4v, webm, mpeg, mov, x-flv）しかアップロードできません');
        return;
      }
      isUploading.value = true;
      try {
        const filePath = await upload(files[0]);
        src.value = await download(filePath);
        context.emit('input', filePath);
      } catch (e) {
        notification.error('動画のアップロードに失敗しました');
      } finally {
        isUploading.value = false;
      }
    }

    async function init() {
      if (props.value) {
        const signedUrl = await download(props.value);
        src.value = signedUrl;
      }
    }
    init();

    watch(
      () => props.value,
      () => {
        if (props.value) {
          download(props.value).then((signedUrl) => {
            src.value = signedUrl;
          });
        } else {
          src.value = '';
        }
      }
    );

    return { removeVideo, onFocus, onFileChange, fileInput, src, isUploading };
  },
});
